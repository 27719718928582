<template>
  <div id="app">
    <IndexPage />
  </div>
</template>

<script>
import IndexPage from '@/components/Index.vue'

export default {
  name: 'App',
  components: {
    IndexPage
  }
}
</script>