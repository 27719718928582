<template>
  <div v-html="html"></div>
  <!-- 오류메세지 주석 -->
  <!-- <div >화면 로딩중입니다...</div> -->
</template>

<script>
export default {
  name: 'NewsPaper',

data() {

    return {
      html : '화면 로딩중입니다...'
    }
  },

  created() {
    // 2022.04 suki - 주식신문 url이 너무 길어서 newsid, memberid 를 관리하는 테이블 추가(MPaperManage)
    // news id 와 index 로 path 를 만들고
    // 맨앞에 m , h 로 member path 인지 homepage path 인지 구분한다.
    // h 로 시작하면 session 으로 체크하고 뒤에 붙는 건 newsid 만 붙인다.
    // m 로 시작하면 newsid 뒤 index 를 분리하고 
    // MPaperManage 테이블에서 newsid 와 index 로 조회해서 처리 한다.
    const spath = this.$route.params.spath

    this.getNewsPaper({id:spath})    
  },

  methods: {
    getNewsPaper(param) {
      console.log("getNewsPaper = param=", param)
      this.$axios.post('/newspaper/view', param)
            .then((result) => {
              console.log("getNewsPaper = result=", result)
              if(result.data.success) {
                const { content } =  result.data.data
                // 오류메세지 주석
                // this.$router.push({
                //   name: "NewsPaperDone",
                //   params: {html: content },
                // });
                this.html = content
                this.addJavascript()
              } else {
                this.$router.push({ name: 'Error', params:{mesg:result.data.message} })  
              }
              
              
            }).catch((err) => {
              console.log("err=", JSON.stringify(err))

              //if (process.env.NODE_ENV === 'development') return // 개발환경에서는 page push X
              //let message = err.message
              this.$router.push({ name: 'Error', params:{mesg:JSON.stringify(err)} })
              // 오류메세지 주석
              // this.$router.push({ name: 'Error', params:{mesg:'네트워크 오류가 발생했습니다! <br/>잠시 후 다시 시도해 주세요'} })
            });
    },
    addJavascript() {
        const newsPaperScript = document.createElement('script')
        newsPaperScript.setAttribute('src', 'https://news.infostock.co.kr/staticserving/js/newspaper.js')
        document.head.appendChild(newsPaperScript)
    }
  },
}
</script>