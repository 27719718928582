import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueRouter from 'vue-router'
import NewsPaper from '@/components/NewsPaper.vue'
import NewsPaperDone from '@/components/NewsPaperDone.vue'
import ErrorPage from '@/components/Error.vue'
import NotAuthorizePage from '@/components/NotAuthorize.vue'
/* import the fontawesome core */
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'


/* add icons to the library */
library.add(faTriangleExclamation)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)


axios.defaults.baseURL = process.env.NODE_ENV === 'production'
                          ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL

const headers = {
  'Content-Type': 'application/json; charset=UTF-8',
  "Access-Control-Allow-Origin": "*.infostock.co.kr"
}

axios.defaults.headers = headers
axios.defaults.withCredentials = true
axios.defaults.timeout = 600000

Vue.prototype.$axios = axios

Vue.config.productionTip = false

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '*', redirect: "/NotFound.html" },
    { path: '/NotFound.html', name: 'Error' ,component: ErrorPage },
    { path: '/NotAuthorize.html', name: 'NotAuthorize' ,component: NotAuthorizePage },
    { path: '/:spath', name: 'NewsPaper', component: NewsPaper },
    { path: '/view.html', name: 'NewsPaperDone', component: NewsPaperDone, props: true },
  ]
})
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
