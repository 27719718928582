<template>
  <div v-html="html"></div>
</template>

<script>
export default {
  name: 'NewsPaperDone',

  props: {
    html: {
      type:String,
      default:''
    }
  
  },
  mounted() {
    this.addJavascript()
  },

  methods: {
    
    addJavascript() {
      console.log('addJavascript')
        const newsPaperScript = document.createElement('script')
        newsPaperScript.setAttribute('src', 'https://news.infostock.co.kr/staticserving/js/newspaper.js')
        document.head.appendChild(newsPaperScript)
    }
  },
}
</script>