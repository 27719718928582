<template>
    <section >
        <h2>Server Error</h2>
        <div>{{ $route.params.mesg }}</div>
    <!-- 오류메세지 주석 -->
    <!-- <section class="out">
        <h2>Error</h2>
        <div class="in">
            <h3><font-awesome-icon icon="fa-solid fa-triangle-exclamation" /></h3>
            <span v-html="$route.params.mesg"/>
        </div> -->
    </section>
</template>

<script>
export default {
    name: 'ErrorPage',
    
  
}
</script>

<style>
.out {
 width: 100%;
 text-align: center;
 }
 .in {
 display: inline-block;
 }
</style>